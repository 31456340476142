import React, {useEffect,useState} from 'react';
import {Container, Row, Col, CardDeck, Card, Button} from 'react-bootstrap';
import BarLoader from 'react-spinners/BarLoader';
import adb from '../images/adobe.jpg';
import { Helmet } from 'react-helmet'

const Documents = (props:any) => {
const [loading, setLoading] = useState(false);
const [kb, setKb] = useState([]);
const override = `display: block; margin: 0 auto; margin-top:300px`;

useEffect(()=>{
  setLoading(true); 

  (async () => {
 
    fetch('https://www.staymetrics.com/knowledge-base/')
    .then(response => {
        if (response) {
          return response.json();
        } else {
          throw new Error('Documents Failed To Load');
        }
      })
      .then(data => {
          setKb(data);
          setLoading(false);
        })
      .catch(error => {console.log(error)});
    
  })();

},[]);
 

return (<div className="helpbg">
<Container>
<Helmet><title>Stay Metrics - Resources</title></Helmet>
  <Row> 
    <BarLoader
        color={"#32855a"}
        css={override}
        width={600}
        loading={loading}
    /> 
  </Row>
  {kb && kb.map((doc:any, i:number)=>{
      return (<Row key={i}>
          <Col sm={12}>
            <h3 className="document_cat">{doc.category_name}</h3>
            </Col>
           
            {doc.category_files && doc.category_files.map((item:any, x:number)=>{
                return (
                    <Col className="document_item" sm={4}>
                        <a target="_blank" href={item.document_file}> 
                            <img className="adobeimage" src={adb} />
                                <span className="adobetitle">{item.document_title}</span>
                            
                        </a>
                   </Col>
                );
            })}
           
      </Row>)
  })}
</Container>
   </div>)
}
export default Documents;