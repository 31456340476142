import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import UserCapabilitiesManager from 'managers/userCapabilitiesManager';
import logo from './../images/client_portal_logo.png';

const InvitationAccept = (props) => {

  let { invitation_id } = useParams();

  const [inviteData, setInviteData] = useState({
    password: "",
    password_confirm: "",
    invitation_id: invitation_id
  });

  const [done, setDone] = useState(false);

  const [errors, setErrors] = useState("");

  const handlePassword = (event): void => {
    setInviteData({...inviteData, password: event.target.value});
  };

  const handlePasswordConfirm = (event): void => {
    setInviteData({...inviteData, password_confirm: event.target.value});
  };

  const validatePasswords = (p: string, p2: string) => {
    // Make sure passwords match
    if (p != p2) {
      return {result: false, message: "Passwords do not match"};
    }

    // Make sure we entered a password
    if (p === "") {
      return {result: false, message: "Please enter a password"};
    }

    if (p.length < 8) {
      return {result: false, message: "Please choose a password with 8 or more characters"};
    }

    // For the record, I know I could have done this in a single regex, but I am almost certain
    // that they will make me lower the password security in some way so this will just make
    // my future a little easier after I am jaded with losing the fight for proper password 
    // security

    // Lowercase regex
    const lc = new RegExp("^(?=.*[a-z])");
    if (!lc.test(p)) {
      return {result: false, message: "Please choose a password at least one lower case letter"};
    }

    // Uppercase regex
    const uc = new RegExp("^(?=.*[A-Z])");
    if (!uc.test(p)) {
      return {result: false, message: "Please choose a password at least one upper case letter"};
    }

    // Number regex
    const num = new RegExp("^(?=.*[0-9])");
    if (!num.test(p)) {
      return {result: false, message: "Please choose a password at least one number in it"};
    }

    // Special Character regex
    const spec = new RegExp("^(?=.*[!@#$%^&*])");
    if (!spec.test(p)) {
      return {result: false, message: "Please choose a password at least one special character in it"};
    }

    return {result: true, message: "Ok"}
  };

  const handleSubmission = (): void => {

    const validation = validatePasswords(inviteData.password, inviteData.password_confirm);

    if (validation.result != true) {
      setErrors(validation.message);
    } else {
      setErrors("");
      const userManager = new UserCapabilitiesManager();
      userManager.acceptUserInvitation(inviteData.invitation_id, inviteData.password)
        .then(response => {
          if (response === true) {
            setDone(true);
          } else {
            if (typeof(response) == 'string') {
              setErrors("There was a problem creating your account. Please contact your Client Relationship Manager for assistance. Error Details: " + response.split("\n")[0]);
            } else {
              if (response.status == '404') {
                setErrors("Your invitation was not found or has expired. Please contact your Client Relationship Manager for assistance.");
              } else {
                setErrors("There was a problem creating your account. Please contact your Client Relationship Manager for assistance. Error Details: " + JSON.stringify(response));
              }
            }
          }
        });
    }
  };

  if (done) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-sm-3">
            <h1>Account Created</h1>

            Your account has been created and is ready for you to login. Please click <a href="/">here</a> to login to your account. If you have any questions, please contact your Client Relationship Manager.
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="container bannerBg">
        <div className="row">
          <div className="col-sm-6 offset-sm-3">
            <img className="inviteLogo" src={logo} />
            <h1>Invitation</h1>

            <p>
              Please enter the password you would like to use to login to the Client Portal. 
              Your username will the be the email address your invitation was sent to.
            </p>

            <div className="form-group">
              <label>Password</label>
              <input 
                type="password" 
                className="form-control" 
                onChange={handlePassword}
              />
            </div>

            <div className="form-group">
              <label>Password Confirm</label>
              <input 
                type="password" 
                className="form-control" 
                onChange={handlePasswordConfirm}
              />
            </div>

            <button 
              className="btn btn-primary btn-block inviteButton"
              onClick={handleSubmission}
            >Activate Account</button>
            {errors != ""
              ?
              <div className="alert alert-danger" role="alert">
                { errors }
              </div>
              : ""}
          </div>
        </div>
      </div>
    );
  }
};

export default InvitationAccept;