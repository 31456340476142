import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import InterventionModel from 'models/interventionDataModel';
import InterventionUsersModel from 'models/interventionDataModel';
import userModel from "../models/userModel";

export default class InterventionService {
  private client: AxiosInstance;

  constructor(currentUser: userModel) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'content-type': 'application/json',
        Authorization: 'bearer ' + currentUser.smToken,
      },
    };

    this.client = axios.create(config);
  }

  getContactList() {
    return this.client
      .get < any[] > ('/api/user/contacts')
      .then(function (contacts: {data: any}) {
        return contacts.data;
      })
      .catch((er: any) => console.log(er));
  }
  getContact(id: number) {
    return this.client
      .get < any[] > ('/api/user/contacts/' + id)
      .then(function (contacts: {data: any}) {
        return contacts.data;
      })
      .catch((er: any) => console.log(er));
  }
  addContact(contact: any) {
    return this.client
      .post('/api/user/contacts',  contact )
      .then(() => {
        return true;
      })
      .catch((er: any) => console.log(er));
  }
  editContact(id: number, contact: any) {
    return this.client
      .patch('/api/user/contacts/' + id,  contact )
      .then(() => {
        return true;
      })
      .catch((er: any) => console.log(er));
  }
  deleteContact(id: number) {
    return this.client
      .delete('/api/user/contacts/' + id )
      .then(() => {
        return true;
      })
      .catch((er: any) => console.log(er));
  }
  getInterventions(filter) {
    if (filter){
      return this.client
      .post < InterventionModel[] > ('/api/intervention', filter)
      .then(function (definitions: {
        data: any
      }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
    }
    else{
      return this.client
      .get < InterventionModel[] > ('/api/intervention')
      .then(function (definitions: {
        data: any
      }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
    }
  }

  getClients(){
    return this.client
    .get <any> ('/api/intervention/client')
    .then(function (definitions: {
      data: any
    }) {
      return definitions.data;
    })
    .catch((er: any) => console.log(er));
  }

  getInterventionsUsers() {
    return this.client
      .get < InterventionUsersModel[] > ('/api/user/fellow-users')
      .then(function (definitions: {
        data: any
      }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getInterventionsCompanys() {
    return this.client
      .get <any> ('/api/user/clients')
      .then(function (definitions: {
        data: any
      }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }
  getInterventionsStatuses(): Promise < Array < Object >> {
    return Promise.resolve().then(
      (data) => {
        let ret: Object[] = [];
        ret.push({id: 1, label:"New"});
        ret.push({id: 2, label:"Working"});
        ret.push({id: 3, label:"Trying To Contact"});
        ret.push({id: 4, label:"Completed - Unable To Contact"});
        ret.push({id: 5, label:"Completed - Resolved"});
        ret.push({id: 6 , label:"Completed - Unresolved"});
        ret.push({id: 7 , label:"Closed - Due To Termination"});
        ret.push({id: 7 , label:"Completed - No Intervention Needed"});
        return ret;
      });
  }

  updateUser(participantId: string, surveyTypeId: number, surveyDate: string, data: string) {
    return this.client
      .patch('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/assign', '"' + data + '"')
      .then(() => {
        return true;
      })
      .catch((er: any) => console.log(er));
  }
  updateStatus(participantId: string, surveyTypeId: number, surveyDate: string, data: string) {
    return this.client
      .patch('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/status', '"' + data + '"')
      .then(() => {
        return true;
      })
      .catch((er: any) => console.log(er));
  }

}