import userModel from "../models/userModel";
import SurveyService from "services/surveyService";

export default class SurveyManager {
    private surveyService: SurveyService;
    
    constructor(currentUser: userModel) {
        this.surveyService =  new SurveyService(currentUser);

    }

    getSurvey(participantId: string, surveyTypeId: number, surveyDate: string): Promise<any> {     
        return this.surveyService.getSurvey(participantId, surveyTypeId, surveyDate)
            .then((data: any) => {
                return data;
            })
            .catch((error: any) => console.log(error))

    }
}