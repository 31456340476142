import Utils from 'utils';
import TokenManager from 'managers/tokenManager';
import UserCapabilitiesManager from 'managers/userCapabilitiesManager';

export default class UserModel {
  token: string | null;
  authenticated: boolean;
  smToken: string;
  smTokenExpiration: number;
  allowedReports: string[];
  workspaceId: string;

  constructor() {
    // Initial State
    this.token = '';
    this.authenticated = false;
    this.smToken = '';
    this.smTokenExpiration = 0;
    this.allowedReports = [];
    this.workspaceId = "";

    // See what we have in the storage container
    let storageUser = UserModel.getStorageUser();

    // Look for things that should cause us to have to purge the current user
    if (storageUser !== null) {
      const storageUserObject = JSON.parse(storageUser);

      this.token = storageUserObject.token;
      this.authenticated = storageUserObject.token;
      this.smToken = storageUserObject.smToken;
      this.smTokenExpiration = storageUserObject.smTokenExpiration;
      this.allowedReports = storageUserObject.allowedReports;
      this.workspaceId = storageUserObject.workspaceId; 
    }
  }

  // Determine if the token is valid
  static isValidToken(storageUserObject: UserModel): boolean {
    // If the user is marked as not logged in
    const unAuthenticated = storageUserObject.authenticated === false;
    // The token is listed as expired
    const expiredToken = storageUserObject.smTokenExpiration < Utils.currentLinuxEpoch();
    const invalidWorkspace = (storageUserObject.workspaceId === undefined) || (storageUserObject.workspaceId === "");

    if (unAuthenticated || expiredToken || invalidWorkspace) {
      return false;
    } else {
      return true;
    }
  }

  // Determine if the user has any reports
  static hasReports(): boolean {
    const storageUser = UserModel.getStorageUser();

    if (storageUser !== null) {
      if (JSON.parse(storageUser).allowedReports.length > 0){
        return true;
      }
      else {
        return false;
      } 
    }
    return false;
  }
  
  // Determine if the user has a valid SM JWT token
  static isAuthorized(): boolean {
    const storageUser = UserModel.getStorageUser();

    if (storageUser !== null) {
      return UserModel.isValidToken(JSON.parse(storageUser));
    }
    return false;
  }

  static getStorageUser(): string | null {
    return localStorage.getItem('currentUser');
  }

  static removeStorageUser(): void {
    localStorage.removeItem('currentUser');
  }

  buildLocalStorageObject(): object {
    return {
      token: this.token,
      authenticated: this.authenticated,
      smToken: this.smToken,
      smTokenExpiration: this.smTokenExpiration,
      allowedReports: this.allowedReports,
      workspaceId: this.workspaceId,
    };
  }

  static buildUserFromAzureAdToken(token: string)  {
    let user = new UserModel();

    var tokenManager = new TokenManager();
    var userCapabilitiesManager = new UserCapabilitiesManager();
    
    // @ts-ignore
    return tokenManager.createStayMetricsTokenFromAzureAdToken(token)
        .then((token: any) => {
          if(token === '') return false;
          return userCapabilitiesManager.getUserCapabilities(token)
            .then((capabilities: any) => {
              user.token = token;
              user.authenticated = true;
              user.smToken = token;
              user.workspaceId = capabilities.assignedPowerBiWorkspace;
              user.smTokenExpiration = Utils.currentLinuxEpoch() + (86400 * 180);   // 180 days
              if (capabilities && capabilities.assignedPowerBiReports !== null) {
                user.allowedReports = capabilities.assignedPowerBiReports;  
              }
              
              localStorage.setItem('currentUser', JSON.stringify(user));
              return true;
            })
            .catch((ex: any) => {
              console.log(ex);
              return false;
            });
        })
        .catch((ex: any) => {
          console.log(ex);
          return false;
        });
  }
}
