
interface PreloadModel {
    style: string,
    title: string,
    message: string,
    image: string,
    url: string,
    button_message : string
}


export default class preoloadService {

    retrieveReport(): Promise<Array<PreloadModel>> {
            
        return Promise.resolve().then(
                (data) => {
                            let ret: PreloadModel[] = [];
                            ret.push(
                                {
                                    style: "center",
                                    title: "Stay Metrics is now a part of Tenstreet!",
                                    message: "Are you interested in learning more about Tenstreet’s entire product and service portfolio?",
                                    image: "https://www.tenstreet.com/wp-content/uploads/2020/11/stay_metrics_email_header.png",
                                    url: "https://www.tenstreet.com",
                                    button_message : "Visit Tenstreet"
                                }
                            );
                            ret.push(
                                {
                                    style: "center",
                                    title: "Stay up-to-date on retention insights from Stay Metrics and recruiting insights from Tenstreet!",
                                    message: "",
                                    image: "https://www.staymetrics.com/wp-content/uploads/2020/03/Linkedin-Logo.jpg",
                                    url: " https://www.linkedin.com/company/tenstreet-llc/",
                                    button_message : "Connect with us on LinkedIn"
                                }
                            );                                     
                            return ret;
                        });
        }
}
