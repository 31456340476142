import React from 'react';
import TokenManager from 'managers/tokenManager';
import UserModel from 'models/userModel';
import Utils from 'utils';
const axios = require('axios');

const AzureB2cCallback = (props: any) => {

    let hasErrors = false;
    let errorMessage = "";

    const hash: string = props.location.hash;

    const variables = Utils.parseQueryString(hash);

    if(variables.id_token !== undefined && variables.id_token !== null) {
        // @ts-ignore    
        UserModel.buildUserFromAzureAdToken(variables.id_token).then(x => {
            if(x) {
                window.location.href = '/dashboard';
            } else {
                window.location.href = '/';
            }
        });
    } else if(variables.error !== undefined && variables.error !== null && variables.error_description !== undefined && variables.error_description !== null) {
        const [error_code, error_message] = variables.error_description.split(':');

        switch(error_code) {
            case 'AADB2C90091':     // User cancelled password reset
                window.location.href = '/';
            break;
            case 'AADB2C90118':     // Forgot password request
                axios({
                    method: 'GET',
                    url: process.env.REACT_APP_API_URL + "/api/auth/azureb2c/passwordreset",
                    headers: {
                        "Content-Type": "application/json-patch+json",
                        "accept": "text/plain"
                    }
                  })
                  .then(function (token: any) {
                      window.location.href = token.data;
                  })
                  .catch(function (error: object){
                      console.log(error);
                  });
            break;

            default:
                hasErrors = true;
                errorMessage = error_message;
            break;
        }
        
    } else {
        hasErrors = true;
        errorMessage = 'Unknown error occured';
    }

    if(hasErrors) {
        return (<div>Error: {errorMessage}</div>)
    } else {
        return (<div>Please wait....</div>);
    }
};

export default AzureB2cCallback;