import UserCapabilitiesService from "services/userCapabilitiesService";

export default class UserCapabilitiesManager {

    userCapabilitiesService: UserCapabilitiesService

    constructor() {
        this.userCapabilitiesService = new UserCapabilitiesService();
    }

    getUserCapabilities(token: string): any {
        return this.userCapabilitiesService.getUserCapabilities(token)
            .then(response => {
                return response;
            });
    }

    passwordResetRequest(email: string): Promise<boolean> {
        return this.userCapabilitiesService.passwordResetRequest(email)
            .then((response: boolean) => {
                return response;
            })
    }

    resetPassword(key: string, password: string): Promise<boolean> {
        return this.userCapabilitiesService.resetPassword(key, password)
            .then((response: boolean) => {
                return response
            })
    }

    acceptUserInvitation(key, password) {
        return this.userCapabilitiesService.acceptUserInvitation(key, password)
            .then((response: boolean | string) => {
                return response
            });
    }

}