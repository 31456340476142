import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import NoteModel from 'models/note';
import userModel from "../models/userModel";

export default class SurveyService {
  private client: AxiosInstance;

  constructor(currentUser: userModel) {
    let config: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'content-type': 'application/json',
        Authorization: 'bearer ' + currentUser.smToken,
      },
    };

    this.client = axios.create(config);
  }

  getSurvey(participantId: string, surveyTypeId: number, surveyDate: string) {
    return this.client
      .get<NoteModel[]>('/api/intervention/' + participantId + '/' + surveyTypeId + '/' + surveyDate + '/response')
      .then(function(definitions: { data: any }) {
        return definitions.data;
      })
      .catch((er: any) => console.log(er));
  }

}