import React, { Component } from 'react';
import UserModel from '../../models/userModel';
import {Container, Row, Col,Modal, Form, Button,Table,Tooltip, OverlayTrigger} from 'react-bootstrap';
import {FaQuestionCircle, FaContao} from 'react-icons/fa'
import InterventionManager from '../../managers/interventionManager';
import '../../components/interventions/interventions.css';
import NoteManager from '../../managers/noteManager';
import ClipLoader from 'react-spinners/ClipLoader';
import day7 from '../../images/day7.jpg';
import day45 from '../../images/day45.jpg';

interface statusCallbackType { (target: string, value: any): any }
interface userCallbackType { (target: string, value: any, text:string, email:string): any }
interface IState {
  newNote: string;
  notes: Array<any>;
  assignedUser: string;
  assignedUserId: number;
  assignedStatus: string;
  emailSent: boolean;
  emailSending: boolean;
  emailButton: boolean;
  email: string;
  options: any;
}
type InterventionModalProps = {
 handleStatusUpdate: statusCallbackType,
 handleUserUpdate: userCallbackType,
 item: any,
 currentUser: UserModel,
 users: Array<any>,
 statuses: Array<any>,
 show: boolean,
 hide: (e: any) => void
}


export default class InterventionModal extends React.Component<InterventionModalProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      newNote: "",
      notes: this.props.item.notes,
      assignedUser: "",
      assignedUserId: 0,
      assignedStatus: "",
      emailButton: false,
      emailSending: false,
      emailSent: false,  
      email: "", 
      options: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'}
    };

    this.sendEmail = this.sendEmail.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);

  }
  componentDidMount () {
  }
  
  static getDerivedStateFromProps(props, state) {
    let userName = "";
    let userId = 0;
    
    if (props.item.assignedClientUser) {
      userName = props.item.assignedClientName;
      userId = props.item.assignedClientUser;
    }

    return {
        notes: props.item.notes,
        assignedUserId: userId,
        assignedUserName: userName,
        assignedStatus: props.item.interventionSetStatus,
    };
  }

  formatPhoneNumber = (str) => {
    let cleaned = ('' + str).replace(/\D/g, ''); 
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    };
  
    return null
  };
  sendEmail = () => {
    let noteManager: NoteManager = new NoteManager(this.props.currentUser);

    this.setState({emailButton: false});
    this.setState({emailSending: true});

    noteManager.sendEmail(this.props.item.participantId, this.props.item.surveyTypeId, this.props.item.surveyDate, this.state.assignedUserId).then(()=>{
      this.setState({emailSending: false});
      this.setState({emailSent: true});
    });

    let value = "User Assignment Email Sent";
    noteManager.saveNote(this.props.item.participantId, this.props.item.surveyTypeId, this.props.item.surveyDate, value).then(notes => {
      let temp = this.state.notes;

      temp.push(
        {
          createdAt: new Intl.DateTimeFormat('en-US', this.state.options).format(new Date()),
          createdByUserName: "You",
          note: "Sent User Assignment Email",
          participantId: this.props.item.participantId
        }
      )
      this.setState({notes: temp})
      this.setState({newNote: ""});
    });

  }
  openSurvey = () =>{
    let url = "/survey/" + this.props.item.participantId + "/" + this.props.item.surveyTypeId + "/" + this.props.item.surveyDate + "/" + this.props.item.participantName;
    window.open(url);
  }

  handleUserChange = (event) => {
    let interventionManager: InterventionManager = new InterventionManager(this.props.currentUser);
    let x =  interventionManager.updateUser(this.props.item['participantId'], this.props.item['surveyTypeId'], this.props.item['surveyDate'], event.currentTarget.value);
  
    if(x){
      this.setState({assignedUserId:event.currentTarget.value });
      let temp_user = this.props.users.filter( user => user.clientUserId === parseInt(event.currentTarget.value))[0];  
      let value = "";
      if (temp_user) {
        value = "Intervention Has Been Assigned To User " +  temp_user.firstName + " " + temp_user.lastName;
      }
      else {
        value = "Intervention Has Been Unassigned";
      }
      let noteManager: NoteManager = new NoteManager(this.props.currentUser);

      if (noteManager.saveNote(this.props.item.participantId, this.props.item.surveyTypeId, this.props.item.surveyDate, value))
      {
        let temp = this.state.notes;

        temp.push({
            createdAt: new Intl.DateTimeFormat('en-US', this.state.options).format(new Date()),
            createdByUserName: "You",
            note: value,
            participantId: this.props.item.participantId
        })

        this.setState({notes: temp});
        this.setState({newNote: ""});
        if (temp_user){
          this.setState({emailButton: true});
          this.setState({email: temp_user.emailAddress});
          this.props.handleUserUpdate(this.props.item.id, temp_user.clientUserId ,temp_user.firstName +" "+ temp_user.lastName, temp_user.emailAddress);
    
        }
        else{
          this.setState({emailButton: false});
          this.setState({email: ""});
          this.props.handleUserUpdate(this.props.item.id, 0 ,"" , "");
        }
      }
    }
};

  handleStatusChange = (event) => {
    let interventionManager: InterventionManager = new InterventionManager(this.props.currentUser);
    let x = interventionManager.updateStatus(this.props.item['participantId'], this.props.item['surveyTypeId'], this.props.item['surveyDate'], event.currentTarget.value);
    
    if (x){
      let value = "Status Changed To " + event.currentTarget.value;

      let noteManager: NoteManager = new NoteManager(this.props.currentUser);
      if (noteManager.saveNote(this.props.item.participantId, this.props.item.surveyTypeId, this.props.item.surveyDate, value)){
        let temp = this.state.notes;

        temp.push(
          {
            createdAt: new Intl.DateTimeFormat('en-US', this.state.options).format(new Date()),
            createdByUserName: "You",
            note: value,
            participantId: this.props.item.participantId
          }
        )
        this.setState({notes: temp})
        this.setState({newNote: ""});
      }
      
      this.setState({assignedStatus: event.currentTarget.value });
      this.props.handleStatusUpdate(this.props.item.id, event.currentTarget.value);
    }
  };

  formSubmit = (value) => {
    if (value !== ""){
    let noteManager: NoteManager = new NoteManager(this.props.currentUser);
    noteManager.saveNote(this.props.item.participantId, this.props.item.surveyTypeId, this.props.item.surveyDate, value).then(notes => {
      let temp = this.state.notes;

      temp.push(
        {
          createdAt: new Intl.DateTimeFormat('en-US', this.state.options).format(new Date()),
          createdByUserName: "You",
          note: value,
          participantId: this.props.item.participantId
        }
      )
      this.setState({notes: temp})
      this.setState({newNote: ""});
    });
  }
  };

  render() {
  return (
    <Modal dialogClassName="interventionModal" show={this.props.show} onHide={() => {this.props.hide(false)}} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{this.props.item.participantName} - {this.formatPhoneNumber(this.props.item.phone)} - <a href={"mailto:" + this.props.item.email}>{this.props.item.email}</a></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Container>
        {this.props.item.recruiter !== "No Recruiter" && this.props.item.recruiter !== "" || 
        this.props.item.dispatcher !== "No Dispatcher" && this.props.item.dispatcher !== "" || 
        this.props.item.manager !== "No Manager" && this.props.item.manager !== ""  ?
        <Row className="supportBar">
          {this.props.item.recruiter !== "No Recruiter" && this.props.item.recruiter !== "" 
            ? <Col lg={3}>Recruiter: {this.props.item.recruiter} </Col> : ''}
          {this.props.item.dispatcher !== "No Dispatcher" && this.props.item.dispatcher !== "" 
            ? <Col lg={3}>Dispatcher: {this.props.item.dispatcher} </Col> : ''}
          {this.props.item.manager !== "No Manager" && this.props.item.manager !== "" 
            ? <Col lg={3}>Manager: {this.props.item.manager} </Col> : ''}
        </Row>
        : <></>
        }
        <Row className="statusBar">
        <Col lg={2}>
          {!this.props.item.contact ?
        <a onClick={() => this.openSurvey()}>
          <OverlayTrigger overlay={<Tooltip id={this.props.item.id}>Click on the icon to view the Survey</Tooltip>}>
          <>
            {this.props.item.surveyTypeId === 5 ? <img className="surveyImageSmall" src={day7} /> : <></> }
            {this.props.item.surveyTypeId === 6 ? <img className="surveyImageSmall" src={day45} /> : <></> }
          </>
          </OverlayTrigger>
          <span className="statusLabel">View Survey</span>
        </a> :''}
        
          </Col>
          <Col lg={5}>
            <span className="statusLabel">Current Status:</span>
                  <select value={this.state.assignedStatus} onChange={(event) =>
                                this.handleStatusChange(event)}>
                    {this.props.statuses && this.props.statuses.map((status, index) => {
                        return (
                          <option key={index} value={status.label}>
                            {status.label}
                          </option>
                        );
                      })}
                  </select>
          </Col>
          <Col lg={5}>
          <span className="statusLabel">Assigned User:</span>
          <select className="assignedUserSelect" value={this.state.assignedUserId}  
              onChange={(event) => this.handleUserChange(event)}>
                <option value="0">Unassigned</option>                     
                {this.props.users && this.props.users.map((user, index) => {
                    return (
                      <option key={index} value={user.clientUserId}>
                        {user.firstName} {user.lastName}
                      </option>
                    );
                  })}
              </select>
              {this.state.emailButton ? <Button variant="dark" size="sm" onClick={() => this.sendEmail()}>Send Email</Button> : ''}
              {this.state.emailSending ? <ClipLoader size="20" color={'#ffffff'} loading={this.state.emailSending} /> : ''}
              {this.state.emailSent ? <span className="emailSent">Email Sent</span> : ''}
          </Col>
        </Row>
          <Row className="show-grid panel">
          <Col lg={6}>
          {this.props.item.contactRequest ?           
          <div className="contactRequestDiv">
            <h3>Contact Request</h3>
            <p><FaContao className="plaqRequest-on margin-right" size="35" />
            {this.props.item.contactReason !== "" ? this.props.item.contactReason : 'No response for reason of request'}
            </p>
          </div> : ''}

          {this.props.item.contact && this.props.item.contact.firstInvite ?           
          <div className="contactRequestDiv">
            <h3>First Invite</h3>
            <p>{this.props.item.contact.firstInvite}</p>
          </div> : ''}

          {this.props.item['interventions'] && this.props.item['interventions'].length > 0 ?
            <Table striped bordered hover>
              <thead>
                <tr className="interventionHeadPanel">
                  <th>Intervention</th>
                  <th>Threshold Limit</th>
                  <th>Actual Value</th>
                  <th>Driver Survey Comment</th>
                </tr>
              </thead>
              <tbody className="interventionPanel">
                {this.props.item['interventions'] &&
                  this.props.item['interventions'].map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <p>{item.shortDesc}<OverlayTrigger overlay={<Tooltip id={index}>{item.longDesc}</Tooltip>}>
                          <FaQuestionCircle className="plaqRequest-on" size="12" />
                        </OverlayTrigger></p> 
                        </td>
                        <td>{item.thresholdValue}</td> 
                        <td>{item.measuredValue}</td>
                        <td>{item.followupText}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table> : ''}          
            </Col>
            <Col lg={6}>
            <div className="notesContainer">
                <div className="notesDiv">
                  <div className="enteredNote">
                    Intervention Opportunity Marked as New <br />
                    <span className="noteDate">System: { new Date(Date.parse(this.props.item.surveyDate)).toLocaleString()}</span>
                  </div>
                  {this.state.notes && this.state.notes.map((note, index) => {
                  
                    return (
                      <div key={index} className="enteredNote">
                        {note.note} <br />
                        <span className="noteDate">
                          {note.createdByUserName}: {new Intl.DateTimeFormat('en-US', this.state.options).format(new Date(Date.parse(note.createdAt)))}
                        </span>
                      </div>
                    );
                  })}
                </div>
                </div>
                <hr />   
                  <Form>
                    <Row className="show-grid">
                      <Col xs={12} md={12}>
                        <Form.Group>
                          <Form.Label>Enter Note</Form.Label>
                          <Form.Control id="newNote" value={this.state.newNote} onChange={e => this.setState({ newNote: e.currentTarget.value})} as="textarea" />
                        </Form.Group>
                      </Col>
                      <Col className="rightAlign" xs={12} md={12}>
                        <Button onClick={() => this.formSubmit(this.state.newNote)}>Add Note</Button>
                      </Col>
                    </Row>
                  </Form>
                
            </Col>
          </Row>
        </Container>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {this.props.hide(false)}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
}
