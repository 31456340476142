import ReportService from "services/reportService";
import userModel from "../models/userModel";

export default class ReportManager {
    reportService: ReportService = new ReportService();

    generateEmbedToken(currentUser: userModel, reportId: string) : Promise<any> {
        return this.reportService.getReport(currentUser, reportId)
            .then((data: any) => {
                return data;
            }).catch((error: any) => console.log(error));
    }

    getReportInfo(currentUser: userModel, reportId: string) : Promise<any> {
        return this.reportService.getReport(currentUser, reportId)
            .then((data: any) => {
                return data;
            }).catch((error: any) => console.log(error));
    }

    sendReportUsage(reportUsage: any) : Promise<any> {
        return this.reportService.sendUsageReport(reportUsage)
            .then((data: any) => {
                return data;
            }).catch((error: any) => console.log(error));
    }
}
