import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import './interventions.css';
import { Helmet } from 'react-helmet'

const Uploads = (props: any) => {

    const [currentUser, setCurrentUser] = useState(props.currentuser);

    return (
        <Container>
            <Helmet><title>Stay Metrics - File Upload</title></Helmet>
            <h2 className="clientUploadHeader">File Upload</h2>
            <iframe className="fileUpload" src="https://staymetrics.sharefile.com/remoteupload/55e499f8-27d6-4e7b-9525-282540925dcc" height="500px" scrolling="auto" id="sfRemoteUploadFrame"></iframe>  </Container>
    );
};

export default Uploads;
