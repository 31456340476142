import userModel from "../models/userModel";
const axios = require('axios');

export default class reportService {

    public getReport(currentUser: userModel, reportId: string) {
        return axios({
            method: 'GET',
            url: process.env.REACT_APP_API_URL + "/api/report/" + reportId + "/embed-link",
            headers: {
                "Content-Type": "application/json-patch+json",
                "accept": "text/plain",
                "Authorization": "bearer " + currentUser.smToken
            }
        })
        .then(function (token: any) {
            return token.data;
        })
        .catch(function (error: object){
            console.log(error);
        });
    }

    public sendUsageReport(reportUsage: any) {

        var data = JSON.stringify({ 'source': 'sm_usage_report', 
                                        'user_name': reportUsage.email,
                                        'company_name': reportUsage.clientName,
                                        'report_name': reportUsage.pageName,
                                        'report_uuid': reportUsage.reportID
                                    })

        var xmlBodyStr = "<?xml version='1.0' encoding='UTF-8'?>";
        xmlBodyStr += "<TenstreetData>";
        xmlBodyStr += "<Authentication><ClientId>634</ClientId><Password>7*mAmbHdP69We5wmeQbQ</Password><Service>external_tenstreet_logger</Service></Authentication>";
        xmlBodyStr += "<SMService>usage_report</SMService>";
        xmlBodyStr += "<ExternalInfoJSON>" + data + "</ExternalInfoJSON>";
        xmlBodyStr += "</TenstreetData>";

        var config = {
            headers: {'Content-Type': 'text/xml'}
       };
       
       return axios.post('https://api.tenstreet.com/tenstreet_marketing_guid_listener.php', xmlBodyStr, config)
        .then(function () {
            return;
            })
        .catch(function (error: object){
                console.log(error);
            }); 
    }
}
