import PreloadService from "services/preloadService";
import PreloadDataModel from '../models/preloadDataModel'

export default class PreloadManager {
    preloadService: PreloadService = new PreloadService();

    shuffleArray(array:PreloadDataModel[]) {
        let i = array.length - 1;
        for (; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          const temp = array[i];
          array[i] = array[j];
          array[j] = temp;
        }
        return array;
      }

    getpreloadInfo() : Promise<Array<PreloadDataModel>> {

        let templates: PreloadDataModel[] = [];
        return this.preloadService.retrieveReport().then(data => {
          data.forEach((item: PreloadDataModel) => {
            templates.push({
                style: item.style,
                title: item.title,
                message: item.message,
                image: item.image,
                url: item.url,
                button_message: item.button_message
            });
          });


          return this.shuffleArray(templates);
        });
    }

    static blankObject(): PreloadDataModel {
        return {
          style: "",
          title: "",
          message: "",
          image: "",
          url: "",
	      button_message: ""
        };
      }

}
