import React, {useEffect,useState} from 'react';
import RssLoader from '../components/RssLoader';
import {Container, Row, Col} from 'react-bootstrap';
import { Helmet } from 'react-helmet'

const Dashboard = (props:any) => {
const [currentUser, setCurrentUser] = useState(props.currentuser);

useEffect(()=>{

});

 

return (<div className="dashboard">
<Container>
<Helmet><title>Stay Metrics - Dashboard</title></Helmet>
  <Row> 
    <Col sm={6} className="logo_bg"></Col>
    <Col sm={6}> </Col>
   
  </Row>
</Container>
   </div>)
}
export default Dashboard;