import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Modal,
    Form,
    Button,
    Tooltip,
    OverlayTrigger
} from 'react-bootstrap';
import {FaFilter, FaExpandArrowsAlt, FaUserCircle, FaContao} from 'react-icons/fa';
import SurveyManager from '../../managers/surveyManager';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BarLoader from 'react-spinners/BarLoader';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import 'components/interventions/interventions.css';

const SinglePageSurvey = (props : any) => {
    const [currentUser,
        setCurrentUser] = useState(props.currentuser);
    const [loading,
        setLoading] = useState(false);
    const [survey,
        setSurvey] = useState([]);
    const [driverName,
        setDriverName] = useState('');
    const [surveyLoading,
        setSurveyLoading] = useState(false);

    const { SearchBar } = Search;    
    
    const surveyColumns = [
        {
            text: 'Question Number',
            dataField: 'questionSequence',
            headerStyle: () => {
                return {width: '5%'};
            }
        }, {
            text: 'Question',
            dataField: 'question',
            headerStyle: () => {
                return {width: '50%'};
            }
        }, {
            text: 'Answer',
            dataField: 'answer',
            headerStyle: () => {
                return {width: '40%'};
            }
        }, {
            text: '',
            dataField: 'rating',
            formatter: ratingFormatter,
            headerStyle: () => {
                return {width: '5'};
            }
        }
    ];
    function ratingFormatter(cell, row) {
        switch (row.rating) {
            case 'Negative' || 'Detractor':
                return <span className="negRating">Neg</span>;
                break;
            case 'Neutral' || 'Passive':
                return <span className="neuRating">Neu</span>;
                break;
            case 'Positive' || 'Promoter':
                return <span className="posRating">Pos</span>;
                break;
            default:
                return <span></span>;
        }
    }
    useEffect(() => {
        let surveyManager : SurveyManager = new SurveyManager(currentUser);
        setSurveyLoading(true);
        surveyManager
            .getSurvey(props.match.params.participantId, props.match.params.surveyTypeId, props.match.params.surveyDate)
            .then(survey => {
                setSurvey(survey.answers);
                setSurveyLoading(false);
                setDriverName(props.match.params.participantName);
            });
    }, []);

    return (
        <div className="singleSurvey">
        {surveyLoading ? (
        <div className="loadHolder">
            <h3>Loading...</h3>
            <BarLoader color={'#298459'} loading={surveyLoading} />
        </div>
        ) : (
        <Container>
            <Row>                    <h2>{driverName}</h2></Row>
            <ToolkitProvider keyField="questionSequence" search data={survey} columns={surveyColumns}>
            {props => (
                <>
                <Row className="searchBar">
                    <SearchBar className="searchBar" {...props.searchProps} />
                </Row>
                <div className="reminderClass">
                    <span className="posRatingText">"Positive"</span>, 
                    <span className="negRatingText">"Negative"</span> and 
                    <span className="neuRatingText">"Neutral"</span> are valuable searches</div>
                <BootstrapTable rowClasses="surveyTableRowClass" {...props.baseProps} />
                </> 
            )}
            </ToolkitProvider>
        </Container>
        )}
    </div>
    )
}

export default SinglePageSurvey;