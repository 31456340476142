import React from 'react';
import UserModel from 'models/userModel';

const Logout = (props: any) => {
  UserModel.removeStorageUser();
  window.location.href = 'http://www.staymetrics.com';
  return <></>;
};

export default Logout;
