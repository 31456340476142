const axios = require('axios');

export default class TokenService {

    createTokenFromAzureAdToken(azureAdToken: string) {
        return axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "/api/auth/azuread",
            headers: {
                "Content-Type": "application/json-patch+json",
                "accept": "text/plain"
            },
            data: "\"" + azureAdToken + "\""
        })
        .then(function (token: any) {
            return token.data;
        })
        .catch(function (error: object){
            console.log(error);
            return "";
        });

    }
}
