import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Form, Button} from 'react-bootstrap';
import InterventionManager from 'managers/interventionManager';
import './interventions.css';
import { Helmet } from 'react-helmet'

const ContactsForm = (props: any) => {

    const [currentUser, setCurrentUser] = useState(props.currentuser);
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [saved, setSaved] = useState(false);
    const [notSaved, setNotSaved] = useState(false);

    let interventionManager: InterventionManager = new InterventionManager(currentUser);
    let id = props.match.params.contact_id;

    useEffect(() => {
        if (id !== undefined){ 
            setLoading(true);
            interventionManager.getContact(id).then(data => {
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setEmail(data.email);
                setLoading(false);
            });
        }
    }, []);    
    
    const onShowAlert = ()=>{
        window.setTimeout(()=>{
            setSaved(false);
            setNotSaved(false);
            window.location.href = "/contacts"
        },1000)
    }
    const submit = () => {
        setLoading(true);
        var contact = {"firstname": firstName, "lastname": lastName, "email": email};
        
        interventionManager.getContactList().then(users => {   
            var found = users.find(user => user.email === email);
            
            if (found === undefined){
                interventionManager.addContact(contact).then(data => {
                    setLoading(false);
                    setSaved(true);
                    onShowAlert();
                });
            }
            else{
                setNotSaved(true);
            }
            setLoading(false);
        });


    }
    const update = () => {
        setLoading(true);
        var contact = {"id": parseInt(id), "firstname": firstName, "lastname": lastName, "email": email};
        
        interventionManager.editContact(id, contact).then(data => {
            setLoading(false);
            setSaved(true);
            onShowAlert();
        });
    }
    return (
        <Container>
            <Helmet><title>Stay Metrics - Contact</title></Helmet>
                <Row className="contactListHeader"><h2>Contact</h2></Row>
                <Row>
                    <Col lg={6}><p>Adding a user will allow them to be assigned to and receive an email with intervention information in it.</p></Col>
                    <Col lg={6} className="text-right">
                    <Alert className="addAlert" color="info" variant='success' show={saved} >Success</Alert>
                    <Alert className="addAlert" color="info" variant='warning' show={notSaved} >User Already Exists</Alert>
                    <Form>
                        <Form.Row>
                            <Col>
                                <Form.Control value={firstName} onChange={(e)=>{setFirstName(e.currentTarget.value);}} placeholder="First name" />
                            </Col>
                            <Col>
                                <Form.Control value={lastName} onChange={(e)=>{setLastName(e.currentTarget.value);}} placeholder="Last name" />
                            </Col>
                        </Form.Row>
                        <br></br>
                        <Form.Row>
                            <Col>
                                <Form.Control value={email} onChange={(e)=>{setEmail(e.currentTarget.value);}} placeholder="Email Address" />
                            </Col>
                        </Form.Row>  
                        <br></br>
                        <Form.Row>
                            <Col>                    
                                {id === undefined && firstName !== "" && lastName !== "" && email !=="" ?
                                <Button  onClick={(e)=>{ submit() }} variant="primary" size="sm">Add</Button>
                                : ''}
                                {id !== undefined && firstName !== "" && lastName !== "" && email !=="" ?
                                <Button  onClick={(e)=>{ update() }} variant="primary" size="sm">Update</Button>
                                : ''}
                                &nbsp;
                                <Button onClick={()=> props.history.goBack() } variant="danger" size="sm">Cancel</Button>
                            </Col>
                        </Form.Row>
                    </Form>
                    </Col>
                </Row>
            </Container>
    );
};

export default ContactsForm;
