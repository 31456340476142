import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom' 
import { Container, Row, Col, Alert,Button } from 'react-bootstrap';
import {FaUserCircle,FaPeopleCarry } from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';
import BarLoader from 'react-spinners/BarLoader';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InterventionManager from 'managers/interventionManager';
import './interventions.css';
import { Helmet } from 'react-helmet'

const Contacts = (props: any) => {

    const [currentUser, setCurrentUser] = useState(props.currentuser);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({"id": 0, "firstName": "", "lastName": "", "email": ""});

    let interventionManager: InterventionManager = new InterventionManager(currentUser);

    useEffect(() => {
        setLoading(true);
        interventionManager.getContactList().then(users => {   
            //delete all tenstreet.com users.
            users = users.filter(user => user.email.indexOf("tenstreet.com") === -1)
            //yeah we have to hard code the Sales Demo account.
            if (!users.find(user=> user.clientId === "0014U0000268z15QAA" )){
                users = users.filter(user => user.email.indexOf("staymetrics.com") === -1)           
            }

            setUsers(users);
            setLoading(false);
        });
    }, []);

    const { SearchBar } = Search;
    const confirmDelete = () => {
        setLoading(true);
        interventionManager.deleteContact(selectedUser.id).then(data => {
            setSelectedUser({"id": 0, "firstName": "", "lastName": "", "email": ""});
            interventionManager.getContactList().then(users => {
                setUsers(users);
                setLoading(false);
            });
        });
    }

    const toolsFormat = (cell,row) =>{
        if (row.isContactOnly){
            return(<div>
                    <Button href={`/contacts/edit/${row.id}`} variant="primary" size="sm">Edit</Button>&nbsp;
                    <Button onClick={()=>{setSelectedUser(row)}} variant="danger" size="sm">Delete</Button>
                </div>);
        }
        else{
            return (<span></span>)
        }

    }
    const typeFormat = (cell,row) =>{
        if (row.isContactOnly){
            return (<FaPeopleCarry color="#1c7430" size="20" />);
        }
        else{
            return (<FaUserCircle color="#003267" size="20" />);
        }
    }
    const columns = [
    {
        text: 'Type',
        sort: true,
        dataField: 'isContactOnly',
        formatter: typeFormat,
        headerStyle: () => {
            return { width: '5%' };
        },
    },
    {
        text: 'First Name',
        dataField: 'firstName',
        sort: true,
        headerStyle: () => {
            return { width: '20%' };
        },
    },
    {
        text: 'Last Name',
        dataField: 'lastName',
        sort: true,
        headerStyle: () => {
            return { width: '20%' };
        },
    },
    {
        text: 'Email Address',
        dataField: 'email',
        headerStyle: () => {
            return { width: '30%' };
        },
    },
    {
        text: '',
        dataField: 'id',
        formatter: toolsFormat,
        headerStyle: () => {
            return { width: '20%' };
        },
    },
    ];


    return ( <div>
        {loading ? (
            <div className="loadHolder">
                <h3>Loading...</h3>
                <BarLoader color={'#298459'} loading={loading} />
            </div>) : (
            <Container>       
            <Helmet><title>Stay Metrics - Contact List</title></Helmet>
                <Row className="contactListHeader"><h2>Contact List</h2></Row>
                <Row>
                    <Col lg={10}><p>The list below contains a list of users who have a role in the Stay Metrics Client Portal. </p>
                        <p><FaUserCircle color="#003267" size="20" /> is a user who can log in and view reports and interventions.</p>
                        <p><FaPeopleCarry color="#1c7430" size="20" /> is a contact who is allowed to receive an email with intervention information in it.</p></Col>
                    <Col lg={2} className="text-right">
                        <Link className="btn btn-success" to="/contacts/new">New Contact</Link>
                    </Col>
                </Row>
                {selectedUser.id ?
                <Row>
                    <Alert className="centerDanger" variant='danger'>
                    <p>Do you really want to delete the user: <strong>{selectedUser.firstName} {selectedUser.lastName}</strong></p>
                    <Button className="confirmDelete" onClick={()=>{confirmDelete()}} variant="danger" size="sm">Confirm</Button>
                    <Button onClick={()=>{setSelectedUser({"id": 0, "firstName": "", "lastName": "", "email": ""}) }} variant="warning" size="sm">Cancel</Button>
                    </Alert>
                </Row> : ''}

                <Row>
                    
                    <Col lg={12}>
                        <ToolkitProvider keyField="id" data={users} columns={columns}>
                            {props => <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />}
                        </ToolkitProvider>
                    </Col>
                </Row>
            </Container>)}
    </div> );
};

export default Contacts;
