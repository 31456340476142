import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaFilter, FaExpandArrowsAlt, FaUserCircle, FaContao,FaLifeRing } from 'react-icons/fa';
import InterventionModel from '../components/interventions/InterventionModal';
import SurveyManager from '../managers/surveyManager';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import InterventionManager from '../managers/interventionManager';
import DatePicker from 'react-datepicker';
import BarLoader from 'react-spinners/BarLoader';
import day7 from '../images/day7.jpg';
import day45 from '../images/day45.jpg';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

import '../components/interventions/interventions.css';
import { Helmet } from 'react-helmet'

const Interventions = (props: any) => {
  const [currentUser, setCurrentUser] = useState(props.currentuser);
  const [loading, setLoading] = useState(false);
  const [interventions, setInterventions] = useState([]);
  const [users, setUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [companys, setCompanys] = useState([]);
  const [clients, setClients] = useState([]);
  const [surveyModal, setSurveyModal] = useState(false);
  const [interventionModal, setInterventionModal] = useState(false);
  const [survey, setSurvey] = useState([]);
  const [surveyLoading, setSurveyLoading] = useState(false);
  const [activeItem, setActiveItem] = useState([]);
  const [driverName, setDriverName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('all');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [search, setSearch] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(new Set());
  const [surveyType, setSurveyType] = useState(new Set());
  const [contactRequest, setContactRequest] = useState(false);
  const [noResponse, setNoResponse] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const [workingStatus, setWorkingStatus] = useState(false);
  const [tryStatus, setTryStatus] = useState(false);
  const [unableStatus, setUnableStatus] = useState(false);
  const [resolvedStatus, setResolvedStatus] = useState(false);
  const [unresolvedStatus, setUnresolvedStatus] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [notNeededStatus, setNotNeededStatus] = useState(false);
  const [firstSurvey, setFirstSurvey] = useState(false);
  const [earlySurvey, setEarlySurvey] = useState(false);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [isClientHidden, setIsClientHidden] = useState(true);
  const [isTerminalHidden, setIsTerminalHidden] = useState(true);
  const [isDivisionHidden, setIsDivisionlHidden] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  let interventionManager: InterventionManager = new InterventionManager(currentUser);

  useEffect(() => {
    setIsPaneOpen(false);
    setLoading(true);

    interventionManager.getClients().then(clientList => {
        setClients(clientList);
        interventionManager.getInterventionsUsers().then(users => {

          //delete all tenstreet.com users.
          users = users.filter(user => user.emailAddress.indexOf("tenstreet.com") === -1)

          //yeah we have to hard code the Sales Demo account.
          if (!users.find(user=> user.clientId === "0014U0000268z15QAA" )){
            users = users.filter(user => user.emailAddress.indexOf("staymetrics.com") === -1)           
          }

          setUsers(users);
          
          interventionManager.getInterventionsCompanys().then(companys => {
            let temp = [];
            companys.forEach(clientItem => {
              let company =  clientList.find(client => client.salesForceId === clientItem.salesForceId);
              if (company){
                temp.push({name: company.name, salesForceId: company.salesForceId});
              }
            })

            setCompanys(temp);
    
            if (companys.length > 1){
              setIsClientHidden(false);
            }
    
            interventionManager.getInterventionsStatuses().then(statuses => {
              setStatuses(statuses);
              interventionManager.getInterventions('').then(interventions => {
                setInterventions(interventions);
                let obj = interventions.find(o => o.terminal !== 'No Terminal');
                let div = interventions.find(o => o.division !== 'No Division');

                if (obj){
                  setIsTerminalHidden(false);
                }
                if (div){
                  setIsDivisionlHidden(false);
                }

                setLoading(false);
              });
            });
          });
        });
    });


  }, []);

  const filterInterventions = () => {
    let filter = {
      search: search,
      clientId: selectedCompany,
      selectedUserId: selectedUserId,
      startDate: startDate,
      endDate: endDate,
      status: Array.from(status),
      surveyType: Array.from(surveyType),
      contactRequest: contactRequest,
      noResponse: noResponse
    };

    toggleMenu();
    setLoading(true);
    interventionManager.getInterventions(filter).then(interventions => {
      setInterventions(interventions);
      let obj = interventions.find(o => o.terminal !== 'No Terminal');
      let div = interventions.find(o => o.division !== 'No Division');

      if (obj){
        setIsTerminalHidden(false);
      }
      if (div){
        setIsDivisionlHidden(false);
      }

      setLoading(false);

    });
  };

  const toggleMenu = () => {
    setIsPaneOpen(false);
  };

  const clearFilterInterventions = () => {
    setIsPaneOpen(false);
    window.location.href = 'interventions';
  };
  const toggleCheckbox = e => {
    let x = e.currentTarget.value;
    if (status.has(x)) {
      status.delete(x);
    } else {
      status.add(x);
    }
    switch (x) {
      case 'New':
        setNewStatus(!newStatus);
        break;
      case 'Working':
        setWorkingStatus(!workingStatus);
        break;
      case 'Trying To Contact':
        setTryStatus(!tryStatus);
        break;
      case 'Completed - Unable To Contact':
        setUnableStatus(!unableStatus);
        break;
      case 'Completed - Resolved':
        setResolvedStatus(!resolvedStatus);
        break;
      case 'Completed - Unresolved':
        setUnresolvedStatus(!unresolvedStatus);
        break;
      case 'Closed - Due To Termination':
        setTermStatus(!termStatus);
        break;
      case 'Completed - No Intervention Needed':
        setNotNeededStatus(!notNeededStatus);
        break;
      default:
    }
  };
  const toggleSurveyCheckbox = e => {
    let x = e.currentTarget.value;
    if (surveyType.has(x)) {
      surveyType.delete(x);
    } else {
      surveyType.add(x);
    }
    switch (x) {
      case '5':
          setFirstSurvey(!firstSurvey);
        break;
      case '6':
          setEarlySurvey(!earlySurvey);
        break;
      default:
    }
  };

  const toggleContactRequestCheckbox = e => {
    if (contactRequest === true) {
      setContactRequest(false);
    } else {
      setContactRequest(true);
    }
  };
  const toggleNoResponseCheckbox = e => {
    if (noResponse === true) {
      setNoResponse(false);
    } else {
      setNoResponse(true);
    }
  };
  const updateStatus = (target, value) => {
    let temp = interventions;
    let item = interventions.filter(i => i.id === target)[0];
    item.interventionSetStatus = value;

    setInterventions(temp);
    setActiveItem(item);
  };
  const updateUser = (target, value, text, email) => {
    let temp = interventions;
    let item = interventions.filter(i => i.id === target)[0];

    item.assignedClientUser = value;
    item.assignedClientName = text;
    item.assignedClientEMail = email;
    setInterventions(temp);
    setActiveItem(item);
  };
  const getSurvey = (participantName, participantId, surveyTypeId, surveyDate) => {
    let surveyManager: SurveyManager = new SurveyManager(currentUser);
    setSurveyModal(true);
    setSurveyLoading(true);
    surveyManager.getSurvey(participantId, surveyTypeId, surveyDate).then(survey => {
      setSurvey(survey.answers);
      setSurveyLoading(false);
      setDriverName(participantName);
    });
  };

  const { SearchBar } = Search;
  const SurveyModal = props => {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" dialogClassName="surveyModal" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{driverName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {surveyLoading ? (
            <div className="loadHolder">
              <h3>Loading...</h3>
              <BarLoader color={'#298459'} loading={surveyLoading} />
            </div>
          ) : (
            <Container>
              <ToolkitProvider keyField="id" search data={survey} columns={surveyColumns}>
                {props => (
                  <>
                    <Row className="searchBar">
                      <SearchBar className="searchBar" {...props.searchProps} />
                    </Row>
                    <div className="reminderClass">
                      <span className="posRatingText">"Positive"</span>, 
                      <span className="negRatingText">"Negative"</span> and 
                      <span className="neuRatingText">"Neutral"</span> are valuable searches</div>
                    <BootstrapTable rowClasses="surveyTableRowClass" {...props.baseProps} />
                  </>
                )}
              </ToolkitProvider>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  const populateInterventionModal = row => {
    setActiveItem(row);
    setInterventionModal(true);
  };

  const surveyColumns = [
    {
      text: 'Question Number',
      dataField: 'questionSequence',
      headerStyle: () => {
        return { width: '5%' };
      },
    },
    {
      text: 'Question',
      dataField: 'question',
      headerStyle: () => {
        return { width: '50%' };
      },
    },
    {
      text: 'Answer',
      dataField: 'answer',
      headerStyle: () => {
        return { width: '40%' };
      },
    },
    {
      text: '',
      dataField: 'rating',
      formatter: ratingFormatter,
      headerStyle: () => {
        return { width: '5' };
      },
    },
  ];
  function ratingFormatter(cell, row) {
    switch (row.rating) {
      case 'Negative' || 'Detractor':
        return <span className="negRating">Neg</span>;
        break;
      case 'Neutral' || 'Passive':
        return <span className="neuRating">Neu</span>;
        break;
      case 'Positive' || 'Promoter':
        return <span className="posRating">Pos</span>;
        break;
      default:
        return <span></span>;
    }
  }

  const columns = [
    {
      text: '',
      dataField: 'id',
      formatter: selectFormatter,
    },
    {
      text: 'Date',
      dataField: 'surveyDate',
      formatter: dateFormatter,
      sort: true,
    },
    {
      text: 'Client',
      dataField: 'clientSalesForceId',
      hidden: isClientHidden,
      formatter: clientFormatter,
      sort: true,
    },
    {
      text: 'Terminal',
      dataField: 'terminal',
      filter: textFilter(),
      hidden: isTerminalHidden,
      formatter: terminalFormatter,
      sort: true,
    },
    {
      text: 'Division',
      dataField: 'division',
      hidden: isDivisionHidden,
      filter: textFilter(),
      formatter: divisionFormatter,
      sort: true,
    },
    {
      text: 'Name',
      formatter: nameFormatter,
      sort: true,
      dataField: 'lastName',
    },
    {
      text: 'Survey',
      formatter: surveyFormatter,
      dataField: 'surveyTypeId',
      sort: true,
    },
    {
      text: 'Intervention',
      dataField: 'risk',
      headerStyle: () => {
        return { width: '25%' };
      },
      formatter: interventionFormatter,
      sort: true,
    },
    {
      text: 'Status',
      formatter: statusFormatter,
      dataField: 'interventionSetStatus',
      sort: true,
    },
    {
      text: 'User',
      formatter: userFormatter,
      dataField: 'assignedClientName',
      sort: true,
    },
  ];

  function clientFormatter(cell, row) {
    return clients.find(client => client.salesForceId === row.clientSalesForceId).name;
  }
  function terminalFormatter(cell, row) {
    return row.terminal;
  }
  function divisionFormatter(cell, row) {
    return row.division;
  }

  function selectFormatter(cell, row) {
    return <FaExpandArrowsAlt color="#003267" onClick={() => populateInterventionModal(row)} size="20" />;
  }

  function nameFormatter(cell, row) {
    return (
      <>
        <span>{row.participantName}</span>
        <br />
        <span>{row.participantUserName} </span>
      </>
    );
  }

  function statusFormatter(cell, row) {
    return <span>{row.interventionSetStatus}</span>;
  }
  function userFormatter(cell, row) {
    if (row.assignedClientUser !== 0) {
      return (
        <span>
          <FaUserCircle color="#003267" size="15" />
          <span className="userIcon">{row.assignedClientName}</span>
        </span>
      );
    } else {
      return <span></span>;
    }
  }

  function surveyFormatter(cell, row) {
    if (!row.contact){
      if (row.surveyTypeId === 5)
        return (
          <OverlayTrigger overlay={<Tooltip id={row.id}>Click on the icon to view the First Impressions Survey</Tooltip>}>
            <img className="surveyImage" onClick={() => getSurvey(row.participantName, row.participantId, row.surveyTypeId, row.surveyDate)} src={day7} />
          </OverlayTrigger>
        );
      else if (row.surveyTypeId === 6)
        return (
          <OverlayTrigger overlay={<Tooltip id={row.id}>Click on the icon to view the Early Experience Survey</Tooltip>}>
            <img className="surveyImage" onClick={() => getSurvey(row.participantName, row.participantId, row.surveyTypeId, row.surveyDate)} src={day45} />
          </OverlayTrigger>
        );
      }
  }
  function dateFormatter(cell, row) {
    return (
      <>
        <span>{new Intl.DateTimeFormat('en-US').format(new Date(Date.parse(row.surveyDate)))}</span>
        <br />
        {row.participantStatus !== 'Active' ? <span className="nonActive">Participant {row.participantStatus}</span> : ''}
      </>
    );
  }

  function interventionFormatter(cell, row) {
    return (
      <div className="interventionItem">
        {row.contact ?
        <div className="plaqContact">
          <OverlayTrigger overlay={<Tooltip id={row.id}>When visible, this person has not responded to our survey</Tooltip>}>
            <FaLifeRing className='redPlaqRequest-on' size="40" />
          </OverlayTrigger>
        </div> 
        :
        <>
        <div className="plaqContact">
          <OverlayTrigger overlay={<Tooltip id={row.id}>When highlighted, this icon signals a contact request</Tooltip>}>
            <FaContao className={row.contactRequest ? 'plaqRequest-on' : 'plaqRequest-off'} size="40" />
          </OverlayTrigger>
        </div>
        <OverlayTrigger overlay={<Tooltip id={row.id}>Intervention Scale</Tooltip>}>
          <div className={row.risk ? 'plaqRisk-on' : 'plaqRisk-off'}>
            <div style={{ width: row.risk }} className="riskMeter"></div>
          </div>
        </OverlayTrigger>
        </>}
        {row.contact && row.surveyTypeId === 5 ?
                  <OverlayTrigger overlay={<Tooltip id={row.id}>Participant did not take the First Impressions Survey</Tooltip>}>
                    <img className="surveyImage" src={day7} />
                  </OverlayTrigger> : ''}
        {row.contact && row.surveyTypeId === 6 ?
                  <OverlayTrigger overlay={<Tooltip id={row.id}>Participant did not take the Early Experience Survey</Tooltip>}>
                    <img className="surveyImage" src={day45} />
                  </OverlayTrigger> : ''}
      </div>
    );
  }

  return (
    <div>
      <Helmet><title>Stay Metrics - Interventions</title></Helmet>
      {loading ? (
        <div className="loadHolder">
          <h3>Loading...</h3>
          <BarLoader color={'#298459'} loading={loading} />
        </div>
      ) : (
        <div>
          <div className="menuFilterHolder" onClick={(e)=>{setIsPaneOpen(true)}}>
            <FaFilter size="20" />
            <span>Apply Filters</span>
          </div>
          <SlidingPane
            className="slidePaneMenu"
            overlayClassName="some-custom-overlay-class"
            isOpen={isPaneOpen}
            from="left"
            width="700px"
            title="Exit"
            onRequestClose={() => {
              setIsPaneOpen(false);
            }}
          >
        <div>
        <div>
              <span className="menuLabel">Search</span>
              <input
                value={search}
                onChange={e => {
                  setSearch(e.currentTarget.value);
                }}
              ></input>
          </div>
            <br />
            <div className="clear" />
            <h4>Who</h4>
            <div>
              <ul className="statusList statusDiv">
                <li>
                  <span className="menuLabel-company">Company</span>
                  <select className="menuUser" value={selectedCompany} onChange={event => setSelectedCompany(event.currentTarget.value)}>
                    <option value="all">All Companies</option>
                    {companys &&
                      companys.map((company, index) => {
                        return (
                          <option key={index} value={company.salesForceId}>
                            {company.name}
                          </option>
                        );
                      })}
                  </select>
                </li>
                </ul>
                <ul className="statusList">
                <li>
                  <span className="menuLabel">User</span>
                  <select className="menuUser" value={selectedUserId} onChange={event => setSelectedUserId(event.currentTarget.value)}>
                    <option value="all">All Users</option>
                    <option value="''">Unassigned</option>
                    {users &&
                      users.map((user, index) => {
                        return (
                          <option key={index} value={user.clientUserId}>
                            {user.firstName} {user.lastName}
                          </option>
                        );
                      })}
                  </select>
                  </li>
              </ul>
            </div>
            <br />
            <div className="clear" />
            <h4>Date</h4>
            <div>
              <span className="menuLabel">Begin</span>
              <DatePicker className="dateLabel" dateFormat="MM/dd/yyyy" selected={startDate} onChange={date => setStartDate(date)} />
              <span className="menuLabel">End</span>
              <DatePicker className="dateLabel" dateFormat="MM/dd/yyyy" selected={endDate} onChange={date => setEndDate(date)} />
            </div>
            <br />
            <div>
              <h4>Status</h4>
              <ul className="statusList">
                <li><Form.Check type="checkbox" value={'New'} checked={newStatus} onChange={toggleCheckbox} label="New" /></li>
                <li><Form.Check type="checkbox" value={'Working'} checked={workingStatus} onChange={toggleCheckbox} label="Working" /></li>
                <li><Form.Check type="checkbox" value={'Trying To Contact'} checked={tryStatus} onChange={toggleCheckbox} label="Trying To Contact" /></li>
                <li><Form.Check type="checkbox" value={'Closed - Due To Termination'} checked={termStatus} onChange={toggleCheckbox} label="Closed - Due To Termination" /></li>
              </ul>
              <ul className="statusList">
                <li><Form.Check type="checkbox" value={'Completed - Unable To Contact'} checked={unableStatus} onChange={toggleCheckbox} label="Completed - Unable To Contact" /></li>
                <li><Form.Check type="checkbox" value={'Completed - Resolved'} checked={resolvedStatus} onChange={toggleCheckbox} label="Completed - Resolved" /></li>
                <li><Form.Check type="checkbox" value={'Completed - Unresolved'} checked={unresolvedStatus} onChange={toggleCheckbox} label="Completed - Unresolved" /></li>
                <li><Form.Check type="checkbox" value={'Completed - No Intervention Needed'} checked={notNeededStatus} onChange={toggleCheckbox} label="Completed - No Intervention Needed" /></li>
              </ul>
            </div>
            <br />
            <div className="clear" />
            <div>
              <h4>Type</h4>
              <ul className="statusList">
                <li><Form.Check type="checkbox" onChange={toggleSurveyCheckbox} checked={firstSurvey} value="5" label="First Impressions" /></li>
              </ul>
              <ul className="statusList">
                <li><Form.Check type="checkbox" onChange={toggleSurveyCheckbox} checked={earlySurvey} value="6" label="Early Experience" /></li>
              </ul> 
              <ul className="statusList">
                <li><Form.Check type="checkbox" onChange={toggleContactRequestCheckbox} checked={contactRequest} value="true" label="Contact Request Only" /></li>
              </ul>
              <ul className="statusList">
                <li><Form.Check type="checkbox" onChange={toggleNoResponseCheckbox} checked={noResponse} value="true" label="No Response" /></li>
              </ul>
            </div>
            <div className="clear" />
            <br />
            <Button className="filterButton" onClick={() => filterInterventions()}>
              Filter
            </Button>
            <Button className="clearButton" onClick={() => clearFilterInterventions()}>
              Clear
            </Button>
        </div>
        <br />
      </SlidingPane>
          <Container>
            <Row>
              <Col lg={12}>
                <ToolkitProvider keyField="id" data={interventions} columns={columns} >
                  {props => <BootstrapTable {...props.baseProps} pagination={paginationFactory()} filter={ filterFactory()} />}
                </ToolkitProvider>
              </Col>
            </Row>
          </Container>
          <SurveyModal show={surveyModal} onHide={() => setSurveyModal(false)} />
          <InterventionModel
            handleUserUpdate={(target, value, text, email) => updateUser(target, value, text, email)}
            handleStatusUpdate={(target, value) => updateStatus(target, value)}
            item={activeItem}
            currentUser={currentUser}
            users={users}
            statuses={statuses}
            show={interventionModal}
            hide={e => setInterventionModal(e)}
          />
        </div>
      )}
    </div>
  );
};

export default Interventions;
